<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <!-- <img
        :src="require('@/page/namvaytien/components/img/logo.png')"
        :alt="logo"
        class="logoImg"
      /> -->
        <span class="title">THINK COMPANY LIMITED</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">Trang chủ</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">Về chúng tôi</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/question' }" class="Li3">
          <router-link to="/question" class="nav-link3">
            <span class="routeText3">Trung tâm trợ giúp</span>
          </router-link>
        </li>
        <!-- <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li4">
        <a href="https://now.happycredito.com/B9tK7aQ4p/j5oYsj8U7ysxy.html?V3mT1yU6b=happycredit_ios" class="nav-link3" target="_blank">
            <span class="routeText4">Chính sách bảo mật</span>
          </a>
      </li> -->
        <li class="dropdown Li4">
          <!-- 添加下拉图标 -->
          <span class="dropdown-toggle routeText4">Chính sách bảo mật</span>
          <ul class="dropdown-menu">
            <li class="firstLi">
              <a
                href="https://now.happycredito.com/B9tK7aQ4p/j5oYsj8U7ysxy.html?V3mT1yU6b=happycredit_ios"
                target="_blank"
              >
                <div class="dropdown-menu-content">
                  <img
                    src="@/page/generandorlq/components/img/app.png"
                    alt="App Store"
                    class="iconImg"
                  />
                  App Store
                </div>
              </a>
            </li>
            <li>
              <a
                href="https://now.happycredito.com/B9tK7aQ4p/j5oYsj8U7ysxy.html?V3mT1yU6b=happycredit"
                target="_blank"
              >
                <div class="dropdown-menu-content">
                  <img
                    src="@/page/generandorlq/components/img/play.png"
                    alt="Google Play"
                    class="iconImg"
                  />
                  Google Play
                </div>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      logo: "NAM A ASSET MANAGEMENT COMPANY LIMITED",
      backgroundImage: "@/page/namvaytien/components/img/Rectangle.png",
      link1Color: "white",
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
  height: 90px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* height: 90px; */
  padding: 0 15px;
  background-color: #00807d;
  /* box-shadow: 0px 2px 28px rgba(50, 55, 71, 0.10); */
}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
  margin-left: 30px;
}

.title {
  width: 500px;
  /* height: 24px; */
  font-size: 18px;
  font-family: DM Sans-Bold;
  font-weight: 600;
  color: #ffffff;
  /* line-height: 23px; */
  padding-left: 17px;
  /* text-transform: uppercase; */
  /* line-height: 35px; */
  height: 90px;
  line-height: 90px;
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #ffffff;
  /* line-height: 29px; */
  /* text-transform: uppercase; */
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;

  margin: 0 auto;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  display: flex;
  /* padding: 6px; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* height: 39px; */
  padding-left: 4px;
  padding-right: 4px;
  line-height: 90px;
  /* border: 1px solid red; */
}
.Li1 {
  width: 130px;
}
.Li2 {
  width: 140px;
}
.Li3 {
  width: 190px;
}
.Li4 {
  width: 200px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}
.Li5 {
  width: 210px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}
.nav-links li {
  background-size: 100% 30%;
}
.active-link {
  background: url("@/page/happycreditapp/components/img/Active_Link.png");
  /* background-color: #EBF7FF; */
  background-size: 100% 100%; /* 背景图片充满整个容器 */
  background-repeat: no-repeat;
}
.active-link .routeText {
  color: white;
}
.active-link span {
  color: #ffffff;
  font-weight: 600;
}

.title {
  /* border: 1px solid red; */
  width: 320px;
}
.secondBox {
  padding: 0 120px 0 140px;
}

.dropdown {
  position: relative;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 10%;
  background-color: #fff;
  padding: 5px 0;
  min-width: 150px;
  border-radius: 5px;
  box-shadow: 0px 6px 12px #ccc;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.dropdown-menu li a {
  font-size: 15px;
  text-decoration: none;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #000000;
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  /* cursor: pointer; */
  /* height: 100%; */
  height: 45px;
  margin: 0 24px;
}

.dropdown-icon {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  /* 设置倒三角形的颜色 */
  border-bottom: none;
  /* 去除下边框 */
  margin-left: 5px;
  vertical-align: middle;
}

.firstLi {
  border-bottom: 1px solid rgb(212, 212, 212);
}

.iconImg {
  width: 18px;
  height: 18px;
  padding-left: -20px;
  margin: 0 auto;
  margin-right: 10px;
}
.dropdown-menu-content {
  display: flex;
  align-items: center;
}
</style>